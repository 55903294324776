// 
// _table.scss
// 

.table {
    th {
        font-weight: $font-weight-bold;
    }

    .table-light {
        color: $body-color;
        border-color: var(--#{$prefix}table-border-color);
        background-color:var(--#{$prefix}table-head-bg);
    }
}

.table-bordered {
    border: $table-border-width solid var(--#{$prefix}table-border-color);
  
    th,
    td {
      border: $table-border-width solid var(--#{$prefix}table-border-color);
    }
  }


.table-dark>:not(:last-child)>:last-child>* {
    border-bottom-color: var(--#{$prefix}table-border-color);
}

.table-nowrap {

    th,
    td {
        white-space: nowrap;
    }
}


.table> :not(:first-child) {
    border-top: 0;
}


[data-bs-theme ="dark"]{
    .table-light{
        background-color: var(--#{$prefix}light);
        color: var(--#{$prefix}gray-400);
        th, td{
            background-color: var(--#{$prefix}light);
        }
    }
}